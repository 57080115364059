import { useState } from "react";

import {
  Button,
  Stack,
  InputAdornment,
  TextField,
  IconButton,
  Tooltip,
  Container,
  MenuItem,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import UploadSharpIcon from "@mui/icons-material/UploadSharp";
import { config } from "../config.js";
import axios from "axios";

export default function UploadButtonWithDialogForm() {
  const genres = config.validValues.filters.genres;
  const contentRatings = config.validValues.filters.contentRatings;
  const [open, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [title, setTitle] = useState("");
  const [selectedGenre, setSelectedGenre] = useState("");
  const [selectedContentRating, setSelectedContentRating] = useState("");
  const [releaseDate, setReleaseDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    const isSubmit = e.target.textContent === "Submit";

    if (isSubmit) {
      postNewVideo({
        videoUrl,
        thumbnailUrl,
        title,
        selectedGenre,
        selectedContentRating,
        releaseDate,
      });
    }

    setOpen(false);

    setVideoUrl("");
    setThumbnailUrl("");
    setTitle("");
    setSelectedGenre("");
    setSelectedContentRating("");
    setReleaseDate(new Date().toISOString().split("T")[0]);
  };

  const postNewVideo = async (dataObj) => {
    const formattedDateString = new Date(
      dataObj.releaseDate
    ).toLocaleDateString("en-IN", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    const payload = {
      videoLink: `${dataObj.videoUrl}`,
      title: `${dataObj.title}`,
      genre: `${dataObj.selectedGenre}`,
      contentRating: `${dataObj.selectedContentRating}`,
      releaseDate: `${formattedDateString}`,
      previewImage: `${dataObj.thumbnailUrl}`,
    };
    try{
      await axios.post(`${config.backend_endpoint}/videos`, payload)
    }catch(err){
      console.log(err)
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<UploadSharpIcon />}
      >
        Upload
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Upload Video</DialogTitle>
        <DialogContent>
          <Stack spacing={2} mt={1}>
            <TextField
              label="Video link"
              placeholder="eg: youtube.com/embed/hGrRg8aoBMU"
              helperText="Xflix supports only youtube embed links currently"
              fullWidth
              value={videoUrl}
              onChange={(e) => setVideoUrl(e.target.value)}
            />
            <TextField
              label="Thumbnail image link"
              helperText="This link will be used to derive video thumbnail"
              placeholder="eg: https://i.ytimg.com/vi/gPgO6vx1UiU/mqdefault.jpg"
              value={thumbnailUrl}
              onChange={(e) => setThumbnailUrl(e.target.value)}
            />
            <TextField
              label="Title"
              helperText="Please provide a descriptive title for the video"
              placeholder="eg: Top 10 Best practices"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />

            <TextField
              select
              label="Genre"
              value={selectedGenre}
              onChange={(e) => {
                setSelectedGenre(e.target.value);
              }}
              helperText="Genre will help in categorizing your video"
            >
              {genres.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              select
              label="Suitable age group for the video"
              value={selectedContentRating}
              onChange={(e) => {
                setSelectedContentRating(e.target.value);
              }}
              helperText="This will be used to filter videos on age group suitability"
            >
              {contentRatings.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label="Release Date"
              type="date"
              value={releaseDate}
              onChange={(e) => setReleaseDate(e.target.value)}
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Stack>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "flex-start" }}>
          <Button onClick={handleClose} variant="contained" color="error">
            Submit
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
