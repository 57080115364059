import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";
import {
  Container,
  Stack,
  Box,
  Divider,
  LinearProgress,
  Typography,
  Chip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SentimentDissatisfied } from "@mui/icons-material";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";

import ReactPlayer from "react-player";

import Header from "./Header";
import Dashboard from "./Dashboard";
import { config } from "../config";

const useStyles = makeStyles({
  videoWrapper: {
    position: "relative",
    paddingTop: "56.25%",
  },
  videoPlayer: {
    position: "absolute",
    top: "0",
    left: "0",
  },
  loadingDashboard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "50vh",
    color: "grey",
  },
});

const VideoPlayPage = (props) => {
  const classes = useStyles();
  const params = useParams();
  const [videoObject, setVideoObject] = useState(null); //null if video not fetched, 'error' if fetch failed, otherwise is an object
  const videoNotFetched = videoObject === null;
  const videoFetchFailed = videoObject === "error";
  const [downVoteIsClicked, setDownVoteIsClicked] = useState(false);
  const [upVoteIsClicked, setUpVoteIsClicked] = useState(false);
  const [downVoteUpdateDebounceTimer, setDownVoteUpdateDebounceTimer] =
    useState(null);
  const [upVoteUpdateDebounceTimer, setUpVoteUpdateDebounceTimer] =
    useState(null);

  const fetchVideoToPlay = async (videoId) => {
    setVideoObject(null);
    try {
      const response = await axios.get(
        `${config.backend_endpoint}/videos/${videoId}`
      );
      setVideoObject(response.data);
    } catch (error) {
      console.error(error);
      setVideoObject("error");
    }
  };

  useEffect(() => {
    fetchVideoToPlay(params.videoId);
    scrollToTop();
    setDownVoteIsClicked(false);
    setUpVoteIsClicked(false);
  }, [params]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  const debounceUpVoteUpdate = (
    videoId,
    voteType,
    updateType,
    debounceTimeout = 500
  ) => {
    clearTimeout(upVoteUpdateDebounceTimer);
    if (updateType === "increase") {
      const newTimer = setTimeout(
        () => updateVote(videoId, voteType, updateType),
        debounceTimeout
      );
      setUpVoteUpdateDebounceTimer(newTimer);
    }
  };

  const debounceDownVoteUpdate = (
    videoId,
    voteType,
    updateType,
    debounceTimeout = 500
  ) => {
    clearTimeout(downVoteUpdateDebounceTimer);
    if (updateType === "increase") {
      const newTimer = setTimeout(
        () => updateVote(videoId, voteType, updateType),
        debounceTimeout
      );
      setDownVoteUpdateDebounceTimer(newTimer);
    }
  };

  const handleUpVoteClick = () => {
    const newUpVoteIsClickedValue = !upVoteIsClicked;
    const voteUpdateType =
      newUpVoteIsClickedValue === true ? "increase" : "decrease";
    setUpVoteIsClicked(newUpVoteIsClickedValue);
    debounceUpVoteUpdate(videoObject._id, "upVote", voteUpdateType);
  };

  const handleDownVoteClick = () => {
    const newDownVoteIsClickedValue = !downVoteIsClicked;
    const voteUpdateType =
      newDownVoteIsClickedValue === true ? "increase" : "decrease";
    setDownVoteIsClicked(newDownVoteIsClickedValue);
    debounceDownVoteUpdate(videoObject._id, "downVote", voteUpdateType);
  };

  const updateVote = async (videoId, voteType, updateType) => {
    //voteType can be 'upVote' or 'downVote'
    //updateType can be 'increase' or 'decrease' 
    //debounce function filters and disallows cases where updateType is 'decrease'
    //Do patch with body json {"vote":`${voteType}`, "change":`${updateType}`}
    const patchRequestBody = { vote: `${voteType}`, change: `${updateType}` };
    try {
      await axios.patch(
        `${config.backend_endpoint}/videos/${videoId}/votes`,
        patchRequestBody
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Header></Header>
      {videoNotFetched ? (
        <Box className={classes.loadingDashboard}>
          <Box sx={{ width: "10%" }}>
            <LinearProgress />
          </Box>
          <p>Video Loading...</p>
        </Box>
      ) : videoFetchFailed ? (
        <Box className={classes.loadingDashboard}>
          <SentimentDissatisfied fontSize="large" />
          <p>Video request failed</p>
        </Box>
      ) : (
        <Container maxWidth="md" sx={{ mt: 1.5 }}>
          <Box className={classes.videoWrapper}>
            <ReactPlayer
              controls
              url={videoObject.videoLink}
              config={{
                youtube: {
                  playerVars: { modestbranding: 1, rel: 0 },
                },
              }}
              width="100%"
              height="100%"
              className={classes.videoPlayer}
            ></ReactPlayer>
          </Box>
          <Stack
            justifyContent="space-between"
            direction={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            sx={{
              mt: 1,
              mb: 1.5,
            }}
            spacing={1}
          >
            <Box color="white">
              <Typography pb="0.5" variant="h6" component="div">
                {videoObject.title}
              </Typography>
              <Typography variant="caption">
                {videoObject.contentRating} &nbsp; &#9679; &nbsp;{" "}
                {videoObject.releaseDate}
              </Typography>
            </Box>
            <Stack direction="row" spacing={1}>
              <Chip
                icon={<ThumbUpOffAltIcon />}
                label={
                  upVoteIsClicked
                    ? videoObject.votes.upVotes + 1
                    : videoObject.votes.upVotes
                }
                variant={upVoteIsClicked ? "filled" : "outlined"}
                onClick={handleUpVoteClick}
              />
              <Chip
                icon={<ThumbDownOffAltIcon />}
                label={
                  downVoteIsClicked
                    ? videoObject.votes.downVotes + 1
                    : videoObject.votes.downVotes
                }
                variant={downVoteIsClicked ? "filled" : "outlined"}
                onClick={handleDownVoteClick}
              />
            </Stack>
          </Stack>

          <Divider />
        </Container>
      )}
      <Container maxWidth="md" sx={{ mb: 5, mt: 1 }}>
        <Dashboard></Dashboard>
      </Container>
    </div>
  );
};

export default VideoPlayPage;
