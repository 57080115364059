const config = {
  backend_endpoint: `https://xflix-node-be.onrender.com/v1`,
  validValues: {
    filters: {
      genres: ["All", "Education", "Sports", "Comedy", "Lifestyle"],
      contentRatings: ["Anyone", "7+", "12+", "16+", "18+"],
    },
    sortOptions: ["releaseDate", "viewCount"],
  },
  sampleValues: {
    sampleVideoObject: {
      votes: {
        upVotes: 0,
        downVotes: 0,
      },
      _id: "60331f421f1d093ab542447a",
      videoLink: "youtube.com/embed/fqnKlD3PUzg",
      title: "Top 10 Facts - Space [Part 7]",
      genre: "Education",
      contentRating: "16+",
      releaseDate: "5 Oct 2016",
      previewImage: "https://i.ytimg.com/vi/fqnKlD3PUzg/mqdefault.jpg",
      viewCount: 100,
    },
  },
};

export { config }; //named export
