import { Box, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  header: {
    backgroundColor: "#2c2c2c",
  },
  btn: {
    textTransform: "none",
  },
  logoImage: {
    height: "1.5rem",
  },
});

const Header = (props) => {
  const classes = useStyles();
  const children = props.children;
  return (
    <Stack
      className={classes.header}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      px={3}
      pt={2}
      pb={3}
    >
      <Box className="header-title">
        <Link to="/">
          <img
            src="/Xflix-logo-ss.png"
            alt="xflix-logo"
            className={classes.logoImage}
          ></img>
        </Link>
      </Box>

      {children}
    </Stack>
  );
};

export default Header;
