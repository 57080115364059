import {
  Grid,
  CardActionArea,
  CircularProgress,
  LinearProgress,
  Box,
  Container,
} from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useState, useEffect } from "react";
import axios from "axios";
import VideoCard from "./VideoCard";
import { config } from "../config";
import { SentimentDissatisfied } from "@mui/icons-material";

const useStyles = makeStyles({
  loadingDashboard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "30vh",
    color: "grey",
  },
});

const sampleVideoObject = config.sampleValues.sampleVideoObject;

const Dashboard = (props) => {
  const classes = useStyles();
  const [videoList, setVideoList] = useState([]);
  const [videosNotLoaded, setVideosNotLoaded] = useState(true);

  const generateQueryString = () => {
    let queryString = "";
    if (!!props.searchKey) {
      queryString += `title=${encodeURIComponent(props.searchKey.trim())}`;
    }
    if (!!props.genres && props.genres.length > 0) {
      queryString = queryString.length === 0 ? "" : `${queryString}&`;
      queryString += `genres=${props.genres.join(",")}`;
    }
    if (!!props.contentRating) {
      queryString = queryString.length === 0 ? "" : `${queryString}&`;
      queryString += `contentRating=${encodeURIComponent(
        props.contentRating.trim()
      )}`;
    }
    if (!!props.sortBy) {
      queryString = queryString.length === 0 ? "" : `${queryString}&`;
      queryString += `sortBy=${props.sortBy}`;
    }
    return queryString;
  };

  const fetchVideos = async (queries = "") => {
    setVideosNotLoaded(true);
    try {
      const response = await axios.get(
        `${config.backend_endpoint}/videos?${queries}`
      );
      const videos = response.data.videos;
      setVideoList(videos);
    } catch (error) {
      console.error(error);
    }
    setVideosNotLoaded(false);
  };

  useEffect(() => {
    let query = generateQueryString();
    fetchVideos(query);
  }, [props.genres, props.contentRating, props.sortBy]); //on mount and on props change

  useEffect(() => {
    let query = generateQueryString();
    fetchVideos(query);
  }, [props.searchKey]);

  return (
    <div>
      {videosNotLoaded ? (
        <Box className={classes.loadingDashboard}>
          <Box sx={{ width: "25%" }}>
            <LinearProgress />
          </Box>
          <p>Loading Dashboard..</p>
        </Box>
      ) : videoList.length === 0 ? (
        <Box className={classes.loadingDashboard}>
          <SentimentDissatisfied fontSize="large" />
          <p>No videos found</p>
        </Box>
      ) : (
        <Grid container columnSpacing={2} rowSpacing={3} sx={{pt:2}}>
          {videoList.map((videoObj) => {
            return (
              <Grid item xs={12} sm={6} md={3} key={videoObj._id}>
                <Link to={`/video/${videoObj._id}`}>
                  {/* <CardActionArea onClick={() => console.log(videoObj._id)}> */}
                  <VideoCard
                    value={videoObj._id}
                    imageLink={videoObj.previewImage}
                    title={videoObj.title}
                    releaseDate={videoObj.releaseDate}
                  ></VideoCard>
                  {/* </CardActionArea> */}
                </Link>
              </Grid>
            );
          })}
        </Grid>
      )}
    </div>
  );
};

export default Dashboard;
