import {
  Grid,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  MenuItem,
  Menu,
} from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { config } from "../config";

const useStyles = makeStyles({
  filterPanel: {
    backgroundColor: "#2c2c2c",
  },
  btn: {
    textTransform: "none",
  },
});

const genres = config.validValues.filters.genres; //multiselect
const contentRatings = config.validValues.filters.contentRatings; //Only one can be selected at a time
const sortOptions = config.validValues.sortOptions;

const FilterPanel = (props) => {
  const classes = useStyles();
  const isSortMenuOpen = Boolean(props.sortMenuListAnchorEl);

  return (
    <Grid
      container
      className={classes.filterPanel}
      spacing={1}
      rowSpacing={1}
      pb={2}
      px={{ xs: 1, sm: 3, md: 15, lg: 25 }}
    >
      <Grid item xs={9}>
        <ToggleButtonGroup
          aria-label="genre selection"
          value={props.selectedGenres}
          onChange={props.handleGenreSelectionChange}
        >
          {genres.map((genre) => {
            const value = genre;
            const textContent = genre === "All" ? "All Genres" : genre;
            const key = genre.toLowerCase();
            return (
              <ToggleButton
                value={value}
                key={key}
                className={classes.btn}
                aria-label={textContent}
              >
                {textContent}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="outlined"
          className={classes.btn}
          onClick={props.handleClickSortMenuExpand}
          startIcon={<SortIcon />}
        >
          {props.selectedSortOption}
        </Button>
        <Menu
          anchorEl={props.sortMenuListAnchorEl}
          open={isSortMenuOpen}
          onClose={props.handleClickSortMenuClose}
          MenuListProps={{
            "aria-labelledby": "sort-option",
            role: "listbox",
          }}
        >
          {sortOptions.map((option) => (
            <MenuItem
              key={option}
              selected={option === props.selectedSortOption}
              onClick={(e) => props.handleSortOptionSelection(e, option)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </Grid>
      <Grid item xs={12}>
        <ToggleButtonGroup
          aria-label="Content age group selection"
          value={props.selectedContentRating}
          exclusive
          onChange={props.handleContentRatingSelectionChange}
        >
          {contentRatings.map((contentRating) => {
            const value = contentRating;
            const textContent =
              contentRating === "Anyone" ? "Any age group" : contentRating;
            const key = contentRating.toLowerCase();
            return (
              <ToggleButton
                value={value}
                key={key}
                className={classes.btn}
                aria-label={textContent}
              >
                {textContent}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
};

export default FilterPanel;
