import Header from "./Header";
import { Search } from "@mui/icons-material";
import UploadSharpIcon from "@mui/icons-material/UploadSharp";
import {
  Button,
  InputAdornment,
  TextField,
  IconButton,
  Tooltip,
  Container,
} from "@mui/material";
import FilterPanel from "./FilterPanel";
import Dashboard from "./Dashboard";
import UploadButtonWithDialogForm from "./UploadButtonWithDialogForm";
import { useState, useEffect } from "react";
import { config } from "../config";

const sortOptions = config.validValues.sortOptions;

function LandingPage() {
  const [searchFieldInput, setSearchFieldInput] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [selectedGenres, setSelectedGenres] = useState(["All"]); //lifting state from FilterPanel
  const [selectedContentRating, setSelectedContentRating] = useState(""); //lifting state from FilterPanel
  const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0]); //lifting state from FilterPanel
  const [sortMenuListAnchorEl, setSortMenuListAnchorEl] = useState(null); //lifting state from FilterPanel

  const handleSearch = (searchKey = searchFieldInput) => {
    if (searchKey === "") {
      setSearchKey("");
      console.log("No search required. Just display all videos");
    } else {
      setSearchKey(searchKey);
      console.log(`Raise search request with searchText=${searchFieldInput}`);
    }
  };

  //lifting state from FilterPanel
  const handleGenreSelectionChange = (event, newSelectedGenres) => {
    setSelectedGenres(newSelectedGenres);
  };

  //lifting state from FilterPanel
  const handleContentRatingSelectionChange = (
    event,
    newSelectedContentRating
  ) => {
    setSelectedContentRating(newSelectedContentRating);
  };

  //lifting state from FilterPanel
  const handleClickSortMenuExpand = (event) => {
    setSortMenuListAnchorEl(event.currentTarget);
  };

  //lifting state from FilterPanel
  const handleClickSortMenuClose = () => {
    setSortMenuListAnchorEl(null);
  };

  //lifting state from FilterPanel
  const handleSortOptionSelection = (event, option) => {
    setSelectedSortOption(option);
    setSortMenuListAnchorEl(null);
  };

  return (
    <div>
      <Header>
        <Tooltip
          title="Press enter or click button to search"
          placement="bottom-start"
          arrow
        >
          <TextField
            size="small"
            style={{ width: 400 }}
            value={searchFieldInput}
            onChange={(e) => {
              setSearchFieldInput(e.target.value);
              if (e.target.value === "") {
                handleSearch("");
              }
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={(e) => handleSearch()}>
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            placeholder="Search"
            name="search"
          />
        </Tooltip>

        <UploadButtonWithDialogForm/>
      </Header>

      <FilterPanel
        selectedGenres={selectedGenres}
        handleGenreSelectionChange={handleGenreSelectionChange}
        selectedContentRating={selectedContentRating}
        handleContentRatingSelectionChange={handleContentRatingSelectionChange}
        selectedSortOption={selectedSortOption}
        handleSortOptionSelection={handleSortOptionSelection}
        sortMenuListAnchorEl={sortMenuListAnchorEl}
        handleClickSortMenuExpand={handleClickSortMenuExpand}
        handleClickSortMenuClose={handleClickSortMenuClose}
      ></FilterPanel>

      <Container maxWidth='md'>
      <Dashboard
        searchKey={searchKey}
        genres={selectedGenres}
        contentRating={selectedContentRating}
        sortBy={selectedSortOption}
      ></Dashboard>
      </Container>
    </div>
  );
}

export default LandingPage;
